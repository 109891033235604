<template>
  <page-layout>
    <span slot="header-name">{{ $tf('videos') }}</span>
    <el-button
      slot="header-actions"
      name="create-btn"
      class="header-button"
      circle
      icon="el-icon-plus"
      size="small"
      :disabled="$hasNoPermission('ffsecurity.add_videoarchive')"
      @click="$router.push({ path: '/videos/create/' })"
    />
    <video-table slot="content" :disable-fetch-on-mount="true"></video-table>
  </page-layout>
</template>

<script>
import VideoTable from './video.table';
import PageLayout from '@/components/page/layout';

export default {
  name: 'page-videos',
  components: {
    VideoTable,
    PageLayout
  }
};
</script>

<style lang="stylus">
.el-button--small {
  margin-right: 0.3rem;
}
</style>
